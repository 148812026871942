.notFound {
  height: calc(100vh - 88px);
  content: "";
  background: url(../../../public/img/404.svg) no-repeat center;
  background-size: contain;
  position: relative;
}

.notFound__text {
  font-size: 128px;
  font-weight: 500;
  text-align: center;
  color: #384355;
  margin-bottom: 30px;
  padding-top: 50px;
}

.notFound__description {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #384355;
  text-transform: uppercase;
}

@media (max-width: 1600px) {
  .notFound {
    height: calc(100vh - 59px);
  }

  .notFound__text {
    font-size: 86px;
  }

  .notFound__description {
    font-size: 14px;
  }

}

@media (max-width: 1024px) {
  .notFound {
    height: calc(100vh - 59px - 34px);
  }
}

@media (max-width: 768px) {
  .notFound {
    height: calc(100vh - 40px - 10px);
  }
}