.container {
  position: relative;
}

.container-mobile {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}