.main {
  display: flex;
  height: calc(100vh - 88px);
  position: relative;
  background-color: #ECE3E0;
  z-index: 1;
  transition: .4s;
  top: 0;
}

.main-active {
  top: -545px;
}

.main__big-circle {
  display: none;
}

.main__small-circle {
  display: none;
}

.main__big-circle-img-one {
  display: none;
}

.main__big-circle-img-two {
  display: none;
}

.main__box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.main__line {
  width: 3px;
  background-color: #28292D;
}

.main__box-item-left {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .7s;
}

.main__box-item-left-active {
  transform: translate(-50%, -250%);
}

.main__box-item-left-bottom {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 250%);
  transition: .7s;
}

.main__box-item-left-bottom-active {
  transform: translate(-50%, -50%);
}

.main__box-item-best {
  width: 152px;
  height: 52px;
  display: flex;
  border-radius: 45px;
  background-color: #5B6E8F;
  align-items: center;
  justify-content: center;
  font-size: 18.03px;
  font-weight: 350;
  color: #ECE3E0;
  margin-bottom: 12px;
}

.main__box-item-title {
  font-size: 64px;
  font-weight: 400;
}

.main__box-item-description {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 10px;
}

.main__box-item-price {
  font-size: 36px;
  font-weight: 500;
  color: #C84148;
  margin-bottom: 30px;
}

.main__box-item-detail {
  font-family: Cygre;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
}

.main__box-item-characteristic {
  font-size: 20px;
  font-weight: 400;
  line-height: 31.64px;
  width: 70%;
}

.main__box-item-arrow {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 500;
  color: #47618C;
  display: flex;
  gap: 24px;
  align-items: center;
}

.main__box-item-arrow-icon {
  width: 24px;
}

.main__box-item-cart {
  background-color: #FFFAF8;
  width: 489px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 663px;
  border-radius: 40px;
  transition: .4s;
  box-shadow:
    1.31px 3.92px 7.84px 0px rgba(0, 0, 0, 0.1),
    3.92px 14.37px 14.37px 0px rgba(0, 0, 0, 0.09),
    9.15px 32.67px 19.6px 0px rgba(0, 0, 0, 0.05),
    16.99px 57.5px 23.52px 0px rgba(0, 0, 0, 0.01),
    26.13px 90.16px 26.13px 0px rgba(0, 0, 0, 0);
}

.main__box-item-cart-active {
  scale: 1.02;
}

.main__box-item-cart-img {
  width: 471px;
  height: 455px;
}

.main__box-item-cart-info {
  border-top: 1px solid #384355;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-top: 30px;
}

.main__box-item-cart-info-title {
  font-size: 15.68px;
  font-weight: 400;
  text-align: center;
}

.main__box-item-cart-info-price {
  font-size: 26.13px;
  font-weight: 500;
  color: #384355;
}

.main__box-item-circle-wrapper {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 95px;
}

.main__box-item-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #384355;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.main__box-item-circle-small {
  width: 12px;
  height: 12px;
  background-color: #384355;
  border-radius: 50%;
  transition: .3s;
}

.main__box-item-circle-small-active {
  width: 21px;
  height: 21px;
}

.main__navigate-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: absolute;
  bottom: 78px;
  left: 50px;
  z-index: 1;
}

.main__navigate {
  width: 15px;
  height: 15px;
  background-color: #384355;
  border-radius: 50%;
  transition: .5s;
  cursor: pointer;
}

.main__navigate-active {
  height: 48px;
  border-radius: 75px;
}

@media (max-width: 1600px) {
  .main {
    height: calc(100vh - 59px);
  }

  .main__line {
    width: 1px;
  }

  .main__box-item-title {
    font-size: 40px;
  }

  .main__box-item-description {
    font-size: 16px;
    margin-bottom: 18px;
  }

  .main__box-item-price {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .main__box-item-detail {
    font-size: 16px;
  }

  .main__box-item-characteristic {
    font-size: 14px;
    line-height: 22px;
    width: 70%;
  }

  .main__box-item-arrow {
    margin-top: 34px;
    font-size: 16px;
  }

  .main__box-item-cart {
    width: 325px;
    height: 442px;
  }

  .main__box-item-cart-img {
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .main__box-item-cart-info-title {
    font-size: 11px;
  }

  .main__box-item-cart-info-price {
    font-size: 17px;
  }

  .main__box-item-cart-info {
    padding-bottom: 30px;
  }

  .main__box-item-circle-wrapper {
    gap: 20px;
    margin-top: 64px;
  }

  .main__box-item-circle {
    width: 24px;
    height: 24px;
  }

  .main__box-item-circle-small {
    width: 8px;
    height: 8px;
  }

  .main__box-item-circle-small-active {
    width: 14px;
    height: 14px;
  }

  .main__navigate-wrapper {
    bottom: 60px;
    left: 30px;
  }

  .main__navigate {
    width: 10px;
    height: 10px;
  }

  .main__navigate-active {
    height: 32px;
  }

}

@media (max-width: 1024px) {
  .main {
    height: calc(100vh - 93px);
    flex-direction: column;
  }

  .main__box {
    height: 60%;
    left: 63px;
  }

  .main__box-item-left {
    width: 100%;
  }

  .main__box-item-left-bottom {
    width: 100%;
  }

  .main__box-item-title {
    font-size: 55px;
    line-height: 66.06px;
    margin-bottom: 40px;
  }

  .main__box-item-price {
    display: none;
  }

  .main__box-item-detail {
    display: none;
  }

  .main__box-item-description {
    font-size: 20px;
    margin-top: 18px;
    margin-bottom: 18px;
  }

  .main__box-item-characteristic {
    font-size: 20px;
    width: 100%;
  }

  .main__box-item-arrow {
    margin-top: 86px;
    font-size: 28px;
    gap: 38px;
  }

  .main__box-item-arrow-icon {
    width: 32px;
  }

  .main__mobile-wrapper {
    display: flex;
    overflow-x: auto;
    padding-bottom: 70px;
    gap: 24px;
    z-index: 1;
  }

  .main__box-item-cart.first {
    margin-left: 63px;
  }

  .main__box-item-cart.last {
    margin-right: 63px;
  }

  .main__box-item-cart {
    border-radius: 16px;
    width: 247px;
    height: 400px;
  }

  .main__box-item-cart-info-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22.25px;
    text-align: left;
  }

  .main__box-item-cart-info-price {
    font-size: 27px;
    font-weight: 500;
    color: #47618C;
  }

  .main__box-item-cart-info {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    align-items: flex-start;
    gap: 10px;
  }

  .main__box-item-cart-img {
    padding: 20px;
  }

  .main__big-circle {
    width: 100vw;
    height: 100vw;
    background-color: #B5BCCE;
    position: absolute;
    top: -15vw;
    right: -45vw;
    display: block;
    border-radius: 50%;
    z-index: 0;
    transition: .5s;
  }

  .main__small-circle {
    width: 60vw;
    height: 60vw;
    background-color: #B5BCCE;
    position: absolute;
    bottom: -20vw;
    left: -20vw;
    display: block;
    border-radius: 50%;
    z-index: 0;
    transition: .5s;
  }

  .main__big-circle-color {
    background-color: #dfbcb4;
  }

  .main__big-circle-active {
    width: 115vw;
    height: 115vw;
  }

  .main__big-circle-img {
    position: absolute;
    top: 0px;
    right: 0;
    margin-left: auto;
  }

  .main__big-circle-img-one {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    opacity: 1;
    transition: .5s;
    z-index: 1;
    width: 320px;
  }

  .main__big-circle-img-one-active {
    opacity: 0;
  }

  .main__big-circle-img-two {
    position: absolute;
    display: block;
    width: 320px;
    top: 0;
    right: 0;
    z-index: 0;
  }

  .main__small-circle-active {
    width: 75vw;
    height: 75vw;
  }

  .main__small-circle-footer {
    bottom: 0;
  }

  .main__navigate-wrapper {
    z-index: 3;
    bottom: 45%;
    left: 20px;
  }

}

@media (max-width: 768px) {
  .main {
    height: calc(100vh - 50px);
  }

  .main-active {
    top: 0;
    transform: translateY(-100vh);
  }

  .main__box-item-title {
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 5px;
  }

  .main__box-item-description {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .main__box-item-characteristic {
    font-size: 14px;
  }

  .main__box-item-arrow {
    margin-top: 40px;
    font-size: 18px;
    gap: 22px;
  }

  .main__box-item-arrow-icon {
    width: 23px;
  }

  .main__box-item-cart-img {
    height: 30vw;
  }

  .main__box {
    height: 50%;
    width: 75%;
    left: 20px;
    z-index: 1;
  }

  .main__box-item-left-bottom {
    transform: translate(-50%, 270%);
  }

  .main__box-item-left-bottom-active {
    transform: translate(-50%, -50%);
  }

  .main__navigate-wrapper {
    /* left: 7px;
    gap: 8px; */
    display: none;
  }

  .main__navigate {
    width: 7px;
    height: 7px;
  }

  .main__navigate-active {
    height: 22px;
  }

  .main__big-circle {
    width: 110vw;
    height: 110vw;
    top: -18vw;
    right: -50vw;
  }

  .main__big-circle-active {
    width: 130vw;
    height: 130vw;
  }

  .main__small-circle {
    width: 80vw;
    height: 80vw;
    bottom: -20vw;
    left: -20vw;
  }

  .main__small-circle-active {
    width: 90vw;
    height: 90vw;
  }

  .main__big-circle-img-one {
    width: 40vw;
  }

  .main__big-circle-img-two {
    width: 40vw;
  }

  .main__box-item-cart {
    border-radius: 12px;
    width: 33vw;
    height: 56vw;
  }

  .main__box-item-cart.first {
    margin-left: 20px;
  }

  .main__box-item-cart.last {
    margin-right: 20px;
  }

  .main__box-item-cart-info-title {
    font-size: 10px;
    line-height: 12px;
  }

  .main__box-item-cart-info-price {
    font-size: 16px;
    color: #47618C;
  }

  .main__mobile-wrapper {
    gap: 16px;
  }

  .main__box-item-cart-info {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 15px;
    padding-top: 12px;
    gap: 8px;
  }

}