.privacy {
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  padding-bottom: 200px;
}

.privacy>h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 20px;
}

.privacy>p {
  font-size: 14px;
  font-weight: 350;
  line-height: 18.2px;
  margin-bottom: 10px;
}

@media (max-width: 1600px) {
  .privacy {
    width: auto;
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 1024px) {
  .privacy {
    margin-left: 63px;
    margin-right: 63px;
        padding-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .privacy {
    margin-left: 20px;
    margin-right: 20px;
   padding-top: 40px;
  }

    .privacy>h2 {
      margin-bottom: 20px;
      margin-top: 25px;
    }
}