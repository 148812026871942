.payment__productList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.payment__productList-item {
  display: flex;
  gap: 32px;
  width: 100%;

  padding: 24px 0;
  border-bottom: 1px solid #5b6e8f;
}

.payment__productList-item-img {
  min-width: 168px;
  max-width: 168px;
  height: 168px;
  border-radius: 17px;
  padding: 15px;
  background-color: #FFFAF8;
}

.payment__productList-item-info {
  display: flex;
  flex-direction: column;
}

.payment__productList-item-info-title-wrapper {
  display: flex;
  align-items: flex-start;
}

.payment__productList-item-info-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.payment__productList-item-info-title-cross {
  min-width: 14px;
  height: 14px;
  margin-left: 50px;
  margin-top: 3px;
  cursor: pointer;
}

.payment__productList-item-info-title-cross {
  position: relative;
  cursor: pointer;
  margin-left: 50px;
  margin-top: 3px;
}

.payment__productList-item-info-title-cross::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  /* Создаем дополнительную область вокруг кнопки */
}

.payment__productList-item-info--inner {
  display: flex;
  text-transform: uppercase;
  margin-top: 16px;
}

.payment__productList-item-info-text {
  font-size: 12px;
  line-height: 14px;
  font-weight: 350;
  color: #28292d99;
}

.payment__productList-item-info-text:not(:last-child) {
  padding-right: 6px;
  border-right: 1px solid #28292d99;
}

.payment__productList-item-info-text:not(:first-child) {
  padding-left: 6px;
}

.payment__productList-item-info-price-wrapper {
  display: flex;
  margin-top: auto;
  align-items: center;
}

.payment__productList-item-info-quantity {
  font-weight: 350;
  font-size: 14px;
}

.payment__productList-item-info-price {
  font-weight: 500;
  font-size: 16px;
  color: #c84148;
  margin-top: auto;
  margin-left: auto;
}

@media (max-width: 1600px) {
  .payment__productList-item-img {
    min-width: 118px;
    max-width: 118px;
    height: 118px;
  }

  .payment__productList-item-info-title {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
  }

  .payment__productList-item-info-title-cross {
    min-width: 10px;
    height: 10px;
    margin-left: 10px;
    margin-top: -3px;
  }

  .payment__productList-item-info-text {
    font-size: 10px;
  }

  .payment__productList-item-info--inner {
    margin-top: 8px;
  }

  .payment__productList-item-info-price {
    font-size: 14px;
  }

}

@media (max-width: 1024px) {
  .payment__productList {
    max-height: none;
  }

  .payment__productList-item-info {
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .payment__productList-item-info-title-wrapper {
    justify-content: space-between;
  }

  .payment__productList-item-img {
    min-width: 144px;
    max-width: 144px;
    height: 140px;
  }

  .payment__productList-item {
    gap: 42px;
    padding: 32px 0;
  }

  .payment__productList-item-info-title {
    font-size: 12px;
  }

  .payment__productList-item-info-title-cross {
    min-width: 14px;
    height: 14px;
  }

  .payment__productList-item-info--inner {
    margin-top: 20px;
  }

  .payment__productList-item-info-text {
    font-size: 12px;
  }

  .payment__productList-item-info-quantity {
    font-size: 18px;
  }

  .payment__productList-item-info-price {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .payment__productList-item-img {
    min-width: 118px;
    max-width: 118px;
    height: 115px;
  }

  .payment__productList-item {
    gap: 23px;
    padding: 24px 0;
  }

  .payment__productList-item-info-title {
    font-size: 10px;
  }

  .payment__productList-item-info--inner {
    margin-top: 8px;
  }

  .payment__productList-item-info-text {
    font-size: 10px;
  }

  .payment__productList-item-info-quantity {
    font-size: 14px;
  }

  .payment__productList-item-info-price {
    font-size: 14px;
  }

  .payment__productList-item-info-title-cross {
    min-width: 10px;
    height: 10px;
  }
}