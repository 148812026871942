.form {
  width: 0px;
  height: 0px;
  border-radius: 40px;
  background-color: #ECE3E0;
  position: absolute;
  top: 80%;
  left: 5%;
  transform: translate(-50%, -50%);
  transition: .7s;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-active {
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
}

.form__title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: center;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  width: 690px;
  opacity: 0;
  transition: .3s;
  pointer-events: none;
}

.form__wrapper-active {
  opacity: 1;
  pointer-events: auto;
}

.form__wrapper-title {
  font-size: 14px;
  font-weight: 400;
  margin-left: 27px;
  margin-bottom: 12px;
}

.form-input {
  font-size: 14px;
  font-weight: 400;
  padding: 13px 26px;
  border-radius: 33px;
  border: 1px solid #5B6E8F80;
  margin-bottom: 24px;
  color: #5B6E8F99;
}

.form-input-big {
  border-radius: 22px;
}

.form-button {
  background: #5B6E8F;
  color: white;
  font-size: 16px;
  font-weight: 400;
  padding: 13px;
  border-radius: 33px;
  cursor: pointer;
}

.form-button-alert {
  background: white;
  color: black;
}

.form-input-label {
  display: flex;
  gap: 10px;
  margin-bottom: 32px;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: #5B6E8F99;
}

.error::placeholder {
  color: #C84148;
}

.form-input-label input[type='checkbox'] {
  display: none;
}

.form-input-label .custom-checkbox {
  cursor: pointer;
  min-width: 14.55px;
  height: 14.55px;
  border-radius: 3.64px;
  border: 1px solid #5B6E8F80;
  background-color: #FFFAF8;
}

/* Состояние чекбокса, когда он активен */
.form-input-label input[type='checkbox']:checked+.custom-checkbox {
  background-color: #5b6e8f;
}

/* Состояние чекбокса при ошибке */
.form-input-label.error .custom-checkbox {
  border-color: #C84148;
}

.inputs__form-label-link {
  text-decoration: underline !important;
}

@media (max-width: 1600px) {
  .form__title {
    font-size: 16px;
    margin-bottom: 32px;
  }

  .form__wrapper-title {
    font-size: 12px;
    margin-left: 14px;
    margin-bottom: 10px;
  }

  .form-input {
    font-size: 12px;
    padding: 10px 23px;
    margin-bottom: 16px;
  }

  .form-button {
    font-size: 14px;
    padding: 13px;
    border-radius: 33px;
  }

  .form-input-label {
    margin-bottom: 32px;
    font-size: 12px;
  }

  .form__wrapper {
    width: 630px;
  }

}

@media (max-width: 1024px) {
  .form {
    width: 0px;
    height: 0px;
    top: 100%;
    left: 0%;
    z-index: 2;
  }

  .form__wrapper {
    width: 70vw;
  }

  .form-active {
    width: 100%;
    height: 125%;
    top: 50%;
    left: 50%;
  }

  .form__title {
    font-size: 20px;
    margin-bottom: 40px;
  }

  .form__wrapper-title {
    font-size: 14px;
    margin-left: 25px;
    margin-bottom: 10px;
  }

  .form-input {
    font-size: 14px;
    padding: 12px 25px;
  }

  .form-button {
    padding: 18px;
  }

}

@media (max-width: 768px) {
  .form {
    border-radius: 30px;
  }

  .form__wrapper {
    width: calc(100vw - 40px);
  }

  .form__title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .form__wrapper-title {
    font-size: 12px;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .form-input {
    font-size: 12px;
    padding: 7px 20px;
    margin-bottom: 10px;
    border-radius: 14px;
  }

  .form-input-label {
    margin-bottom: 32px;
    font-size: 10px;
  }

  .form-button {
    padding: 14px;
    font-size: 12px;
  }

  .form-active {
    height: 150%;
    top: 43%;
  }

}

@media (max-width: 534px) {
  .form-active {
    height: 140%;
  }

}