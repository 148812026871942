.product-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 128px;
}

.product-list__title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 5px;
}

.product-list__description {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 90px;
}

.product-list__items {
  display: grid;
  grid-template-columns: repeat(3, 315px);
  gap: 40px 62px;
}

@media (max-width: 1600px) {
  .product-list__title {
    font-size: 20px;
    margin-top: 64px;
    margin-bottom: 5px;
  }

  .product-list__description {
    font-size: 14px;
    margin-bottom: 58px;
  }

  .product-list__items {
    gap: 26px 48px;
  }
}

@media (max-width: 1024px) {

  .product-list__items {
    grid-template-columns: repeat(4, 158px);
    gap: 40px 23px;
  }
}

@media (max-width: 768px) {
  .product-list__items {
    grid-template-columns: repeat(2, 158px);
    gap: 23px 23px;
  }

  .product-list {
    margin-bottom: 80px;
  }
}