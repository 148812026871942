.product {
  display: flex;
}

.product__box-size-mobile {
  display: none;
}

.product__box-size-line {
  display: none;
}

.product__box-other-mobile {
  display: none;
}

.product__left {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__left-img {
  width: 600px;
  height: 600px;
  padding: 15px;
  border-radius: 40px;
  background-color: #FFFAF8;
  box-shadow: 1.35px 4.06px 8.12px 0px #0000001A, 4.06px 14.89px 14.89px 0px #00000017, 9.47px 33.83px 20.3px 0px #0000000D, 17.59px 59.54px 24.36px 0px #00000003, 27.07px 93.37px 27.07px 0px #00000000;
}

.product__right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5vw;
}

.product__box-title {
  font-size: 64px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 40px;
}

.product__box-description {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 32px;
}

.product__box-size {
  width: 342px;
  color: #5B6E8F;
  font-size: 12px;
  font-weight: 350;
  margin-bottom: 32px;
}

.product__box-size-wrapper {
  display: flex;
}

.product__box-size-wrapper-left {
  border-right: 0.5px solid #5B6E8F80;
  border-bottom: 0.5px solid #5B6E8F80;
  width: 50%;
  height: 31px;
  display: flex;
  align-items: center;
  padding-left: 55px;
}

.product__box-size-wrapper-right {
  border-bottom: 0.5px solid #5B6E8F80;
  width: 50%;
  text-align: center;
  height: 31px;
  display: flex;
  align-items: center;
  padding-left: 55px;
}

.product__box-other {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 10px;
  margin-left: 10px;
}

.product__box-price {
  display: flex;
  gap: 60px;
  align-items: center;
  margin-bottom: 30px;
}

.product__box-price>input {
  width: 113px;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #75757580;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.product__box-price>input::placeholder {
  color: #28292D80;
}

.product__box-price-number {
  font-size: 20px;
  font-weight: 500;
  color: #C84148;
}

.product__box-ml {
  display: flex;
  gap: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #75757580;
  margin-bottom: 32px;
}

.product__box-ml-active {
  text-decoration: underline;
  text-underline-offset: 10px;
  animation: title .2s linear forwards;
  color: #28292D;
}

@keyframes title {
  0% {
    text-underline-offset: 5px;
  }

  100% {
    text-underline-offset: 10px;
  }
}

.product__box-text {
  font-size: 14px;
  font-weight: 400;
  width: 50%;
}

.product__box-button {
  font-size: 32px;
  font-weight: 500;
  display: flex;
  gap: 42px;
  align-items: center;
  color: #47618C;
  margin-top: 50px;
  margin-bottom: 80px;
}

.product__box-button-img {
  width: 35px;
}

@media (max-width: 1600px) {

  .product__left-img {
    width: 30vw;
    height: 30vw;
  }

  .product__box-title {
    font-size: 32px;
    margin-bottom: 0px;
  }

  .product__box-description {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .product__box-size {
    width: 320px;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .product__box-size-wrapper-left {
    height: 26px;
  }

  .product__box-size-wrapper-right {
    height: 26px;
  }

  .product__box-other {
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 5px;
  }

  .product__box-price>input {
    width: 90px;
    height: 40px;
    font-size: 14px;
  }

  .product__box-price {
    gap: 30px;
    margin-bottom: 30px;
  }

  .product__box-price-number {
    font-size: 16px;
  }

  .product__box-ml {
    font-size: 12px;
  }

  .product__box-text {
    font-size: 12px;
  }

  .product__box-button {
    font-size: 20px;
    gap: 27px;
    margin-top: 40px;
  }

  .product__box-button-img {
    width: 23px;
  }

}

@media (max-width: 1024px) {
  .product {
    flex-direction: column;
    align-items: center;
  }

  .product__left {
    width: 100vw;
    height: calc(100vh - 583px - 59px - 34px);
    align-items: flex-end;
  }

  .product__left-img {
    width: 100%;
    height: 100%;
    border-radius: 30px 30px 0px 0px;
    margin-bottom: -50px;
  }

  .product__left-img>img {
    object-fit: contain;
  }

  .product__right {
    position: relative;
    width: 100%;
    padding-left: 63px;
    padding-right: 63px;
    background-color: #28292D;
    border-radius: 30px 30px 0px 0px;
  }

  .product__box-title {
    font-size: 20px;
    color: #F0EAE7;
    margin-top: 48px;
  }

  .product__box-description {
    font-size: 16px;
    color: #F0EAE7;
    margin-bottom: 20px;
  }

  .product__box-size {
    color: #F0EAE7;
    width: 320px;
    font-size: 12px;
    position: absolute;
    right: 63px;
    top: 48px;
  }

  .product__box-ml {
    gap: 20px;
    font-size: 10px;
    margin-bottom: 20px;
  }

  .product__box-other {
    color: #F0EAE7B2;
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 0px;
  }

  .product__box-other-mobile {
    display: block;
    color: #F0EAE7B2;
    font-size: 12px;
    margin-top: 20px;
  }

  .product__box-ml>li {
    /* width: 37px;
    height: 32px; */
    padding: 9px 11px;
    background-color: #F0EAE71A;
    color: #F0EAE7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product__box-ml>li>a {
    background-color: #ffffff00 !important;
  }

  .product__box-ml-active {
    text-decoration: none;
    text-underline-offset: 0;
    background-color: #F0EAE780 !important;
  }

  .product__box-text {
    color: #ECE3E0;
    width: 100%;
  }

  .product__box-button-img {
    display: none;
  }

  .product__box-button {
    font-size: 14px;
    margin-top: 10px;
    color: #28292D;

    padding: 12px 64px 12px 64px;
    border-radius: 12px;
    background-color: #F0EAE7;
    display: flex;
    width: 63%;
    justify-content: center;
    margin-left: auto;
  }

  .product__box-other-none {
    display: none;
  }

  .product__box-price>input {
    position: absolute;
    padding: 12px 64px 12px 64px;
    border-radius: 12px;
    background-color: #F0EAE780;
    width: 30%;
    height: 47px;
    font-size: 14px;
    bottom: 80px;
    color: #FFFFFFB2;
  }

  .product__box-price>input::placeholder {
    color: #FFFFFFB2;
  }

  .product__box-price-number {
    font-size: 20px;
    color: #F0EAE7;
  }

  .main__line {
    display: none;
  }

  .product__box-size-wrapper-left {
    border-right: 0.5px solid #EEE9E6;
    border-bottom: 0.5px solid #EEE9E6;
  }

  .product__box-size-wrapper-right {
    border-bottom: 0.5px solid #EEE9E6;
  }

}

@media (max-width: 768px) {
  .product__left {
    height: 50vh;
  }

  .product__box-title {
    font-size: 16px;
    margin-top: 40px;
  }

  .product__left-img {
    height: calc(100% + 30px);
  }

  .product__right {
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
  }

  .product__box-price>input {
    position: absolute;
    padding: 0px;
    border-radius: 12px;
    width: 25%;
    height: 47px;
    font-size: 14px;
    bottom: 40px;
  }

  .product__box-button {
    font-size: 14px;
    margin-top: 12px;
    padding: 0px;
    width: 70%;
    height: 47px;
    margin-bottom: 40px;
  }

  .product__box-size-mobile {
    display: block;
    color: #F0EAE7B2;
    font-size: 14px;
    position: absolute;
    top: 40px;
    right: 20px;
  }

  .product__box-other {
    font-size: 10px;
    margin-bottom: 12px;
  }

  .product__box-size {
    width: 100%;
    height: 526px;
    /* background-color: #3e404a; */
    background-color: #384355;
    z-index: 1;
    top: 100px;
    transform: translateY(600px);
    left: 0;
    transition: .5s;
    display: flex;
    flex-direction: column;
    border-radius: 30px 30px 0px 0px;
  }

  .product__box-size-mobile-open {
    transform: translateX(0vh);
  }

  .product__box-size-line {
    width: 80px;
    height: 4px;
    background-color: #EFEAE680;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 24px;
    margin-bottom: 60px;
  }

  .product__box-size-wrapper-left {
    border-right: 0.5px solid #EEE9E6;
    border-bottom: 0.5px solid #EEE9E6;
    height: 35px;
  }

  .product__box-size-wrapper-right {
    border-bottom: 0.5px solid #EEE9E6;
    height: 35px;
  }

}