.item {
  width: 315px;
  border-radius: 23px;
  background-color: #FFFAF8;
  box-shadow: 1.12px 2.24px 4.49px 0px #0000001A, 2.24px 8.97px 8.97px 0px #00000017, 5.61px 19.06px 12.34px 0px #0000000D, 10.09px 33.64px 13.46px 0px #00000003, 14.58px 52.7px 15.7px 0px #00000000;
  padding: 26px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item__img {
  width: 100%;
  height: 100%;
}

.item__title {
  font-size: 13.46px;
  font-weight: 400;
  border-top: 1px solid #28292D80;
  padding-top: 10px;
}

.item__price {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  font-size: 22.43px;
  font-weight: 400;
  color: #5B6E8F;
}

.item__button {
  width: 27px;
  height: 29px;
}

@media (max-width: 1600px) {
  .item {
    width: 282px;
    padding: 22px 16px;
  }

  .item__title {
    font-size: 12px;
    padding-top: 10px;
  }

  .item__price {
    margin-top: 18px;
    font-size: 18px;
  }

  .item__button {
    width: 24px;
    height: 26px;
  }

}

@media (max-width: 1024px) {
  .item {
    width: 100%;
    padding: 13px 19px;
    border-radius: 10px;
  }

  .item__button {
    display: none;
  }

  .item__title {
    font-size: 9px;
  }

  .item__price {
    margin-top: 15px;
    font-size: 16px;
  }



}