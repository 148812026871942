.contact {
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.contact__title {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 90px;
}

.contact__info-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.contact__info {
  font-size: 24px;
  font-weight: 350;
  display: flex;
  gap: 20px;
  align-items: center;
}

.contact__info-icon-map {
  width: 34px;
  height: 55px;
}

.contact__info-icon-tel {
  width: 40px;
  height: 40px;
}

.contact__info-icon-mail {
  width: 45px;
  height: 35px;
}

.contact__info-icon-social {
  width: 40px;
  height: 40px;
}

.contact__info-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 140px;
  margin-top: 70px;
}

.contact__info-card-text-title {
  font-size: 24px;
  font-weight: 350;
  margin-bottom: 24px;
}

.contact__info-card-text-list {
  font-size: 14px;
  font-weight: 350;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 6px;
}

@media (max-width: 1600px) {
  .contact {
    width: 890px;
  }

  .contact__title {
    font-size: 20px;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .contact__info-wrapper {
    margin-bottom: 30px;
  }

  .contact__info {
    font-size: 16px;
    gap: 20px;
  }

  .contact__info-icon-map {
    width: 18px;
    height: 30px;
  }

  .contact__info-icon-mail {
    width: 30px;
    height: 22px;
  }

  .contact__info-icon-tel {
    width: 25px;
    height: 25px;
  }

  .contact__info-icon-social {
    width: 25px;
    height: 25px;
  }

  .contact__info-card-text-title {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .contact__info-card-text-list {
    font-size: 12px;
    gap: 7px;
    margin-left: 6px;
  }

  .contact__info-card {
    gap: 40px;
    margin-bottom: 100px;
  }

  .contact__info-card-text {
    width: 320px;
  }

}

@media (max-width: 1024px) {
  .contact {
    width: 100%;
  }

  .contact__info-wrapper {
    flex-direction: column;
    width: 380px;
    margin-left: auto;
    margin-right: auto;
    gap: 28px;
  }

  .contact__info-card {
    align-items: center;
    flex-direction: column;
    gap: 90px;
  }

  .contact__info-icon-map {
    width: 25px;
    height: 36px;
  }

}

@media (max-width: 768px) {
  .contact {
    width: calc(100vw - 40px);
  }

  .contact__title {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .contact__info-wrapper {
    width: 100%;
  }

  .contact__info {
    gap: 24px;
  }

  .contact__info-card {
    gap: 60px;
    margin-bottom: 50px;
  }

  .contact__info-card-text {
    width: 100%;
  }

}