.thanks-page {
  height: calc(100vh - 88px);
  position: relative;
}

.thanks-page__title {
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  color: #384355;
  padding-top: 20vh;
}

.thanks-page__description {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding-top: 20px;
  color: #384355;
}

@media (max-width: 1600px) {
  .thanks-page {
    height: calc(100vh - 59px);
  }

  .thanks-page__title {
    font-size: 24px;
  }

  .thanks-page__description {
    font-size: 14px;
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .thanks-page {
    height: calc(100vh - 59px - 34px);
  }

  .thanks-page__description {
    padding-top: 24px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .thanks-page {
    height: calc(100vh - 40px - 10px);
  }

  .thanks-page__description {
    width: calc(100% - 40px);
  }
}