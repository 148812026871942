@font-face {
    font-family: 'Cygre';
    font-weight: 400;
    font-style: normal;
    src: local('Cygre'), url(../public/fonts/Cygre-Book.ttf) format('opentype');
}

@font-face {
    font-family: 'Cygre';
    font-weight: 400;
    font-style: italic;
    src: local('Cygre'), url(../public/fonts/Cygre-BookIt.ttf) format('opentype');
}

@font-face {
    font-family: 'Cygre';
    font-weight: 300;
    font-style: normal;
    src: local('Cygre'), url(../public/fonts/Cygre-Light.ttf) format('opentype');
}

@font-face {
    font-family: 'Cygre';
    font-weight: 300;
    font-style: italic;
    src: local('Cygre'), url(../public/fonts/Cygre-LightIt.ttf) format('opentype');
}

@font-face {
    font-family: 'Cygre';
    font-weight: 500;
    font-style: normal;
    src: local('Cygre'), url(../public/fonts/Cygre-Medium.ttf) format('opentype');
}

@font-face {
    font-family: 'Cygre';
    font-weight: 500;
    font-style: italic;
    src: local('Cygre'), url(../public/fonts/Cygre-MediumIt.ttf) format('opentype');
}

body {
    font-family: 'Cygre', sans-serif;
}

textarea,
option,
select,
button,
input,
input::placeholder {
    font-family: 'Cygre', sans-serif;
}