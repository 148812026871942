.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  height: 88px;
  border-bottom: 2px solid #28292D;
  position: relative;
  z-index: 5;
  background-color: #ECE3E0;
}

.header__burger {
  display: none;
}

.header__window-item-none {
  display: none;
}

.header__window-social-icon {
  display: none;
}

.header__nav {
  display: flex;
  gap: 32px;
  margin-left: 65px;
}

.header__nav-link {
  position: relative;
}

.header__nav-click {
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.header__logo {
  width: 69px;
  height: 66px;
}

.header__basket {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-right: 65px;
  width: 433px;
  justify-content: flex-end;
}

.header__basket-icon {
  min-width: 38px;
  height: 35px;
  position: relative;
}

.header__basket-active {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 4px;
  height: 4px;
  background-color: #C84148;
  border-radius: 50%;
}

.header__window {
  position: absolute;
  top: 0;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  gap: 40px;
  height: 88px;
  z-index: 2;
  transition: .5s;
  width: 100%;
  align-items: center;
  background-color: #ECE3E0;
  border-bottom: 2px solid #28292D;
  padding-left: 266px;
}

.header__window-active {
  top: 88px;
}

@media (max-width: 1600px) {
  .header {
    font-size: 14px;
    height: 59px;
    border-bottom: 1px solid #28292D;
  }

  .header__nav {
    display: flex;
    gap: 28px;
    margin-left: 40px;
  }

  .header__basket {
    gap: 28px;
    margin-right: 40px;
    width: 314px;
  }

  .header__nav-click {
    font-size: 14px;
  }

  .header__logo {
    width: 44px;
    height: 39px;
  }

  .header__basket-icon {
    min-width: 25px;
    height: 25px;
  }

  .header__window {
    font-size: 14px;
    gap: 40px;
    height: 59px;
    padding-left: 185px;
    border-bottom: 1px solid #28292D;
  }

  .header__window-active {
    top: 59px;
  }
}

@media (max-width: 1024px) {
  .main__line {
    display: none;
  }

  .header__window-item-none {
    display: block;
  }

  .header {
    align-items: flex-end;
    margin-top: 34px;
    border: none;
    background-color: transparent;
  }

  .header__burger {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 63px;
    padding-right: 8px;
  }

  .header__burger-line {
    width: 27px;
    height: 5px;
    border-radius: 5.5px;
    background-color: #28292D;
  }

  .header__nav {
    display: none;
  }

  .header__basket {
    margin-right: 63px;
    width: auto;
  }

  .header__basket-icon {
    min-width: 35px;
    height: 35px;
  }

  .header__logo {
    width: 56px;
    height: 56px;
  }

  .header__window {
    height: 0px;
    top: 0;
    border: none;
    padding-left: 59px;
    flex-direction: column;
    align-items: flex-start;
    font-size: 23px;
    gap: 27px;
  }

  .header__window>button {
    font-size: 23px;
    margin-top: 90px;
    margin-bottom: 20px;
    color: #47618C;
  }

  .header__window-active {
    height: 100vh;
  }

  .header__window-item {
    display: none;
    position: relative;
  }

  .header__window-item-active {
    display: flex;
    top: 150px;
    animation: product__info-about-text .2s linear forwards;
    animation-delay: .3s;
    opacity: 0;
  }

  @keyframes product__info-about-text {
    0% {
      top: 150px;
      opacity: 0;
    }

    100% {
      top: 180px;
      opacity: 1;
    }
  }

  .header__window-social {
    display: flex;
    gap: 20px;
  }

  .header__window-social-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    display: block;
  }

}

@media (max-width: 768px) {
  .header__logo {
    width: 41px;
    height: 41px;
  }

  .header__burger {
    gap: 6px;
    margin-left: 0px;
    padding-left: 20px;
    padding-bottom: 4px;
    padding-right: 4px;
    padding-top: 10px;
  }

  .header__burger-line {
    width: 20px;
    height: 4px;
    border-radius: 10px;
  }

  .header__basket {
    margin-right: 0px;
    padding-right: 20px;
    padding-top: 5px;
  }

  .header__basket-icon {
    min-width: 24px;
    height: 24px;
  }

  .header {
    margin-top: 10px;
    height: 40px;
  }

  .header__window {
    padding-left: 20px;
    font-size: 16px;
    gap: 18px;
  }

  .header__window>button {
    margin-top: 30px;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .header__window-social-icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

}