.payment {
  color: #28292d;
  display: flex;
  justify-content: space-between;
  width: 1395px;
  margin: 64px auto 104px;
}

.payment__right {
  width: 494px;
  padding-top: 92px;
}

.payment__left-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
}

.payment__left-form {
  margin-bottom: 40px;
}

.payment__left-form-title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
}

.payment__left-form-input {
  width: 100%;
  height: 48px;
  border: 1px solid #28292db2;
  border-radius: 4px;
  padding: 10px;
  background-color: inherit;
  font-size: 14px;
}

.payment__left-form-input--disable::placeholder {
  color: red !important;
}

.payment__left-form-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.payment__left-list {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.payment__left-delivery-wrap {
  margin-bottom: 40px;
}

.payment__left-delivery-item {
  margin-bottom: 24px;
}

.payment__left-delivery-item--disactive {
  border: 1px solid red;
  color: red;
}

.payment__left-delivery-form {
  margin-top: 16px;
}

.payment__left-list-item {
  cursor: pointer;
  padding: 16px;
  width: 839px;
  border: 1px solid;
  border-radius: 4px;
}

.payment__left-list-item-title {
  font-size: 14px;
  font-weight: 350;
  line-height: 28px;
}

.payment__left-list-item-text {
  font-size: 12px;
  font-weight: 350;
  line-height: 24px;
}

.payment__left-payType-list-item {
  color: #5b6e8f99;
}

.payment__left-payType-list-item--active {
  color: #28292d;
}

.payment__left-payType-list-item--disactive {
  color: red;
  border: 1px solid red;
}

/* ---------------------------np-style---------- */
.payment__left-np-input {
  width: 100%;
  background-color: inherit;
  padding: 8px;
  margin-top: 16px;
  border: 1px solid #28292d99;
  border-radius: 4px;
  font-size: 14px;
}

.payment__left-np-input-wrap {
  position: relative;
}

.payment__np-list--wrap {
  z-index: 1;
  position: absolute;
  max-height: 180px;
  overflow: auto;
  background-color: #ece3e0;
  width: 100%;
}

.payment__np-list-item {
  padding: 5px 10px;
  border: 1px solid #28292d99;
  width: 100%;
  border-radius: 4px;
  margin-top: 2px;
  font-size: 14px;
}

/* -------------------------------------------- */

.payment__right-info {
  margin-top: 40px;
}

.payment__right-info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment__right-info-price-text {
  font-size: 20px;
  font-weight: 500;
}

.payment__right-info-price {
  font-size: 24px;
  font-weight: 500;
  color: #c84148;
}

.payment__right-info-submitBtn {
  margin-top: 40px;
  margin-bottom: 16px;
  width: 100%;
  height: 56px;
  background-color: #5b6e8f;
  color: #ffffff;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.payment__right-info-submitBtn:disabled {
  background-color: grey;
  cursor: not-allowed;
  opacity: 0.5;
}

.payment__right-info-text {
  font-size: 13px;
  font-weight: 350;
  color: #6a6e71;
}

.payment__right-info-text-link {
  text-decoration: underline !important;
}

/* -------------empty basket--------------------- */

.payment__right-empty-basket {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 30px;
  border-top: 1px solid #28292d;
  border-bottom: 1px solid #28292d;
  font-size: 13px;
  text-transform: uppercase;
}

.payment__right-empty-basket-icon {
  margin-bottom: 20px;
  width: 200px;
  margin-top: 30px;
}

@media (max-width: 1600px) {
  .payment {
    width: 100%;
    margin: 64px auto 104px;
    justify-content: center;
    gap: 100px;
  }

  .payment__left-title {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .payment__left-form-title {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .payment__left-form-input {
    height: 42px;
    padding: 10px;
    font-size: 12px;
  }

  .payment__left-form-list {
    gap: 8px 17px;
  }

  .payment__left-form {
    margin-bottom: 30px;
  }

  .payment__left-delivery-wrap {
    margin-bottom: 30px;
  }

  .payment__left-list-item {
    padding: 16px;
    width: 510px;
  }

  .payment__right {
    width: 340px;
    padding-top: 30px;
  }

  .payment__right-info-price-text {
    font-size: 14px;
  }

  .payment__right-info-price {
    font-size: 16px;
  }

  .payment__right-info-submitBtn {
    margin-top: 24px;
    margin-bottom: 26px;
    height: 46px;
    font-size: 14px;
  }

  .payment__right-info-text {
    font-size: 10px;
  }

  .payment__right-empty-basket {
    padding-top: 56px;
    padding-bottom: 0px;
    font-size: 10px;
  }
}

@media (max-width: 1024px) {
  .payment {
    flex-direction: column-reverse;
    position: relative;
    align-items: center;
    width: calc(100vw - 126px);
  }

  .payment__right {
    width: 100%;
    padding-top: 50px;
  }

  .payment__left {
    width: 100%;
    margin-bottom: 240px;
  }

  .payment__right-info {
    z-index: 2;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #fffaf8;
    border-radius: 36px 36px 0px 0px;
    padding: 40px 63px;

    box-shadow: 0px -2.45px 6.12px 0px #0000000d, 0px -9.78px 9.78px 0px #0000000a, 0px -23.24px 13.45px 0px #00000008, 0px -40.36px 15.9px 0px #00000003,
      0px -63.6px 17.12px 0px #00000000;
  }

  .payment__left-title {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .payment__left-form-title {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .payment__left-form-list {
    gap: 16px 25px;
  }

  .payment__left-list-item {
    padding: 16px;
    width: 100%;
  }

  .payment__np-list--wrap {
    max-height: 163px;
  }

  .payment__left-np-input {
    font-size: 12px;
  }

  .payment__np-list-item {
    font-size: 12px;
  }

  .payment__left-form {
    margin-bottom: 40px;
  }

  .payment__left-delivery-wrap {
    margin-bottom: 40px;
  }

  .payment__right-info-price-text {
    font-size: 20px;
  }

  .payment__right-info-price {
    font-size: 25px;
  }

  .payment__right-info-submitBtn {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 56px;
    font-size: 17px;
  }

  .payment__right-info-text {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .payment {
    margin: 30px auto 0px;
    width: calc(100vw - 40px);
  }

  .payment__right {
    padding-top: 0px;
  }

  .payment__left-form-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }

  .payment__right-info {
    padding: 20px 20px 10px;
  }

  .payment__left {
    margin-bottom: 200px;
  }

  .payment__right-info-price-text {
    font-size: 16px;
  }

  .payment__right-info-price {
    font-size: 18px;
  }

  .payment__right-info-submitBtn {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 40px;
    font-size: 12px;
  }

  .payment__right-info-text {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .payment__right-empty-basket-icon {
    margin-bottom: 20px;
    width: 150px;
    margin-top: 30px;
  }

  .payment__right-empty-basket {
    padding-top: 50px;
    font-size: 12px;
  }
}