.footer {
  width: 100%;
  height: 545px;
  background-color: #384355;
  position: absolute;
  bottom: 0;
  padding: 90px 64px 23px;
  display: flex;
  justify-content: space-between;
  transition: .5s;
}

.footer-active {
  position: static;
}

.footer-color__mobile-main {
  display: none;
}

.footer__left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__left-img {
  height: 270px;
  width: 240px;
}

.footer__left-text {
  font-size: 12px;
  font-weight: 300;
  color: #ECE3E0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer__right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.footer__right-wrapper {
  display: flex;
  gap: 56px;
}

.footer__right-text-title {
  font-size: 16px;
  font-weight: 400;
  color: #ECE3E0;
  margin-bottom: 16px;
}

.footer__right-text-link {
  font-size: 14px;
  font-weight: 350;
  color: #ECE3E0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer__right-icons {
  display: flex;
  gap: 24px;
}

.footer__right-icons-img {
  width: 32px;
  height: 32px;
}

.footer__right-link {
  font-size: 14px;
  font-weight: 400;
  color: #ECE3E0;
}

.footer__left-text-mobile {
  display: none;
}

@media (max-width: 1600px) {
  .footer {
    height: 362px;
    padding: 50px 40px 20px;
  }

  .footer__left-img {
    height: 135px;
    width: 120px;
  }

  .footer__right-wrapper {
    gap: 50px;
  }

  .footer__right-text-title {
    font-size: 14px;
  }

  .footer__right-text-link {
    font-size: 12px;
    gap: 6px;
  }

  .footer__left-text {
    font-size: 10px;
    gap: 4px;
  }

  .footer__right-icons {
    gap: 18px;
  }

  .footer__right-icons-img {
    width: 24px;
    height: 24px;
  }

  .footer__right-link {
    font-size: 12px;
  }

}

@media (max-width: 768px) {

  .footer {
    height: calc(100% - 50px);
    padding: 50px 40px 20px;
    flex-direction: column;
  }

  .footer-active {
    position: static;
    height: auto;
  }

  .footer__left-img {
    display: none;
  }

  .footer__right-wrapper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  .footer__right {
    align-items: center;
    margin-top: 20px;
  }

  .footer__left {
    align-items: center;
  }

  .footer__right-text-title {
    text-align: center;
  }

  .footer__right-text-link {
    text-align: center;
  }

  .footer__right-link {
    margin-bottom: 20px;
  }

  .footer__left-text {
    display: none;
  }

  .footer-top {
    padding: 50px 40px 30%;
  }

  .footer__left-text-mobile {
    display: block;
    text-align: center;
    color: #ECE3E0;
    font-size: 10px;
    font-weight: 300;
  }

}