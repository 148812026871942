.button {
  font-size: 26.09px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  background-color: #47618C;

  width: 452px;
  height: 75px;
  border-radius: 9.78px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #ECE3E0;
  position: absolute;
  bottom: 100px;
  transform: translateX(-50%);
  left: 50%;
}

.button-img {
  width: 37px;
  height: 37px;
  margin-top: 5px;
}

@media (max-width: 1600px) {
  .button {
    font-size: 16px;
    width: 284px;
    height: 47px;
    gap: 20px;
  }

  .button-img {
    width: 19px;
    height: 13px;
    margin-top: 5px;
  }
}

@media (max-width: 1024px) {
  .button {
    font-size: 17px;
    width: calc(100vw - 126px);
    height: 56px;
  }

  .button-img {
    display: none;
  }
}

@media (max-width: 768px) {
  .button {
    font-size: 17px;
    width: calc(100vw - 40px);
    height: 56px;
    bottom: 130px;
  }
}