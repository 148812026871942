*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #ece3e0;
  color: #28292d;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.big-text {
  font-weight: 500;
}

a,
button {
  text-decoration: none !important;
  color: inherit;
  background-color: inherit;
  border: none;
}

ul,
li {
  list-style: none;
}

textarea,
select,
input:active,
input:focus {
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  overflow-x: hidden;
}

/* отключение видимости скрола */
::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

::-moz-scrollbar {
  width: 0;
  height: 0;
}

::-ms-scrollbar {
  width: 0;
  height: 0;
}